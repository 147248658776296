import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBInput,
}
from 'mdb-react-ui-kit';
import "./App.css";
import logo from "./logo.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  var url = new URL(window.location.href);
  var valu = url.searchParams.get("id");
   
   const [val, setVal] = useState(valu);
  useEffect(() => {

    
  });

  const [users, setUsers] = useState({
    name: "",
    phone: "",
    email: "",
    referred_by_code: val != "" ? val : "",
    password:"",
    re_password: "",
    remark: "",
});

const validateOnlyDigits = (inputtxt) => {
  var phoneno = /^\d+$/;
  if(inputtxt.match(phoneno) || inputtxt.length==0){
      return true;
  }else{
      return false;
  }
}

const onInputChange = (e) => {
  if(e.target.name=="phone"){

      if(validateOnlyDigits(e.target.value)){
        setUsers({
          ...users,
          [e.target.name]: e.target.value,
        });
      }
  }else{
    setUsers({
          ...users,
          [e.target.name]: e.target.value,
      }); 
  }
};



const onSubmit = (e) => {
  if(users.password != users.re_password){
      toast.success("Password doesnot match", {
        type: 'Success',
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
  }
  let data = {
    name: users.name,
    phone: users.phone,
    email: users.email,
    referred_by_code: users.referred_by_code,
    password:users.password,
    re_password: users.re_password,
    remark: users.remark,
  };
  var v = document.getElementById("addIndiv").checkValidity();
  var v = document.getElementById("addIndiv").reportValidity();
  e.preventDefault();

  if (v) {
  
  axios({
      url: "https://chetanaarogyam.com/public/api/insert-user-register",
      method: "POST",
      data,
      headers: {
      Authorization: `Bearer ${localStorage.getItem("login")}`,
      },
  }).then((res) => {
      if(res.data.code == '200'){
          toast.success("Data Inserted Sucessfully", {
            type: 'Success',
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
        setUsers({
          name: "",
          phone: "",
          email: "",
          referred_by_code: "",
          password:"",
          re_password: "",
          remark: "",
        });
      }else if(res.data.code == '401'){
        toast.success("Password doesnot match", {
          type: 'Success',
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
      });
      }else if(res.data.code == '402'){
        toast.success("Mobile No Duplicate Entry", {
          type: 'Success',
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
      });
      }else if(res.data.code == '403'){
        toast.success("Email id Duplicate Entry", {
          type: 'Success',
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
      });
      }else{
          
          toast.error("Duplicate Entry", {
            type: 'error',
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
      }
      
  });
  }
};

  return (
    <>
    <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
    <MDBContainer fluid className='d-flex align-items-center justify-content-center bg-image' style={{backgroundImage: 'url(https://mdbcdn.b-cdn.net/img/Photos/new-templates/search-box/img4.webp)'}}>
      <div className='mask gradient-custom-3'></div>
      <MDBCard className='m-5' style={{maxWidth: '800px',width:'600px'}}>
        <MDBCardBody className='cardsview px-5'>
        <form id="addIndiv" autoComplete="off">
          <h2 className="text-uppercase text-center mb-5"><img src={logo} alt='' className="mobileImg" style={{width:'100px'}}/> Registration Form</h2>
          <MDBInput wrapperClass='mb-4' required label='Full Name' size='lg' id='name' type='text' name="name" value={users.name} onChange={(e) => onInputChange(e)}/>
          <MDBInput wrapperClass='mb-4' required label='Mobile No' minLength="10" maxLength="10" size='lg' id='phone' type='text' inputProps={{ minLength: 10, maxLength: 10 }} name="phone" value={users.phone} onChange={(e) => onInputChange(e)}/>
          <MDBInput wrapperClass='mb-4' required label='Email Id' size='lg' id='email' type='email' name="email" value={users.email} onChange={(e) => onInputChange(e)}/>
          <MDBInput wrapperClass='mb-4' label='Refer By Code' size='lg' id='referred_by_code' type='text' name="referred_by_code" value={users.referred_by_code} onChange={(e) => onInputChange(e)}/>
          <MDBInput wrapperClass='mb-4' required label='Password' size='lg' id='password' type='password' name="password" value={users.password} onChange={(e) => onInputChange(e)}/>
          <MDBInput wrapperClass='mb-4' required label='Repeat your password' size='lg' id='re_password' type='password' name="re_password" value={users.re_password} onChange={(e) => onInputChange(e)}/>
          <MDBBtn className='mb-4 w-100 gradient-custom-4' style={{color:'#000'}} onClick={(e) => onSubmit(e)} size='lg'>Register</MDBBtn>
          <div className='d-flex flex-row justify-content-center mb-4'>
          Have already an account? <a href="https://admin.chetanaarogyam.com"> Login here</a>
          
          </div>
          </form>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
    </>
  );
}

export default App;